import React, { useEffect, useRef, useState } from "react";
// import axios from "axios";
import IBGPT from "../ChatGPT_Clone_assets/assets/IBGPT Logo gradient without bg.png";
import sendBtn from "../ChatGPT_Clone_assets/assets/send.svg";
import user from "../ChatGPT_Clone_assets/assets/user-icon.jpg";
import ReactMarkdown from "react-markdown";
import { MdContentCopy } from "react-icons/md";
import { IoFolderOpen } from "react-icons/io5";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
// import { FaRegCopy } from "react-icons/fa";
// import { useUserContext } from "../context/ContextApi";
import { IconContext } from "react-icons";
import { ImSpinner3 } from "react-icons/im";
import { IoCheckmark } from "react-icons/io5";
import { AiFillLike } from "react-icons/ai";
import { AiFillDislike } from "react-icons/ai";

import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";
import { FaBars, FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  adduserPrompt,
  editMessagesByHistory,
  getRunyojn,
} from "../Slices/RunyojnSlice";
import { changeExpanded } from "../Slices/UserConditonsSlice";
import axios from "axios";
import { USER_FEEDBACK } from "../utils/ApiEndpoints";
// import { uuid } from "../uuid";
// import { uuid } from "../uuid";

const Main = ({ uid }) => {
  const msgEnd = useRef(null);
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const { yojnData } = useSelector((state) => state?.yojnData);
  const userParseDetails = localStorage.getItem("userInfo");
  const userDetails = JSON.parse(userParseDetails);
  const { messages } = useSelector((state) => state?.messages);
  const { expanded, consentValue } = useSelector(
    (state) => state?.userConditions
  );
  // console.log("consent", consentValue);
  // console.log("Googlelogin", googleLogin);
  const status = useSelector((state) =>
    state.messages.status === "Pending" ? true : false
  );
  // console.log("status||", status);
  // console.log("yojnData", messages);
  const [input, setInput] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editing, setEditing] = useState(false);
  // const [convertId, setconvertId] = useState("");
  // const [likebutton, setlikebutton] = useState();
  const [filteredMessages, setFilteredMessages] = useState([]);
  // const text = "good name is yours";
  useEffect(() => {
    msgEnd?.current?.scrollIntoView();
  }, [filteredMessages]);
  useEffect(() => {
    if (messages) {
      let filteredMessages = messages.map((message) => {
        return {
          ...message,
          isCopy: false,
          isLike: false,
          isDislike: false,
        };
      });
      setFilteredMessages(filteredMessages);
    }
  }, [messages]);
  // console.log("filtered???", filteredMessages);
  const handlesend = async () => {
    try {
      if (editIndex !== null) {
        try {
          const text = input;
          setInput("");
          // setMessages([...messages, { text, isBot: false }]);
          dispatch(adduserPrompt({ text, isBot: false }));
          // setLoading(true);
          const payload = {
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
              userPrompt: text,
              userId: userDetails?.email,
              consent: String(consentValue) || "1",
              app: "masterApp",
              groupChatId: uid,
              authKey: process.env.REACT_APP_AUTHKEY,
              orgId: "diaratech",
            }),
          };
          dispatch(getRunyojn({ payload, dispatch }));
          setEditIndex(null);
          setEditing(false);
        } catch (error) {
          console.log({ Error: error.message });
          // setLoading(false);
        }
      } else if (input.trim() !== "") {
        const text = input;
        setInput("");
        // setMessages([...messages, { text, isBot: false }]);
        dispatch(adduserPrompt({ text, isBot: false }));
        // setLoading(true);
        const payload = {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            userPrompt: text,
            userId: userDetails?.email,
            consent: String(consentValue) || "1",
            app: "masterApp",
            groupChatId: uid,
            authKey: process.env.REACT_APP_AUTHKEY,
            orgId: "diaratech",
          }),
        };
        dispatch(getRunyojn({ payload, dispatch }));
      }
    } catch (error) {
      console.log({ Error: error.message });
    }
  };

  const startEditing = (index) => {
    setEditing(true);
    setEditIndex(index);
    setInput(messages[index].text);
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter") await handlesend();
  };

  const cancelEditing = () => {
    setEditIndex(null);
    setInput("");
    setEditing(false);
  };

  const handleButtonClick = () => {
    dispatch(changeExpanded(!expanded));
  };
  const copyText = (message) => {
    navigator.clipboard.writeText(message.text);
    const fiteredMessage = messages?.map((mess) => {
      return {
        ...mess,
        isCopy: mess.conversationId === message?.conversationId,
      };
    });
    setFilteredMessages(fiteredMessage);

    setTimeout(() => {
      const fiteredMessage = messages?.map((mess) => {
        return {
          ...mess,
          isCopy: false,
        };
      });
      setFilteredMessages(fiteredMessage);
    }, 1500);
  };

  const changeLike = async (message) => {
    try {
      await axios.post(USER_FEEDBACK, {
        orgId: "diaratech",
        userFeedback: "1",
        conversationId: message.conversationId,
      });
      // console.log(res.data);
      const fiteredMessage = messages?.map((mess) => {
        return {
          ...mess,
          isLike: mess?.conversationId === message?.conversationId,
        };
      });
      setFilteredMessages(fiteredMessage);
      setTimeout(() => {
        const fiteredMessage = messages?.map((mess) => {
          return {
            ...mess,
            isLike: false,
          };
        });
        setFilteredMessages(fiteredMessage);
      }, 1500);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const handleDislikefeedback = async (message) => {
    try {
      const res = await axios.post(USER_FEEDBACK, {
        orgId: "diaratech",
        userFeedback: "0",
        conversationId: message?.conversationId,
      });
      console.log(res.data);
      const fiteredMessage = messages?.map((mess) => {
        return {
          ...mess,
          isDislike: mess?.conversationId === message?.conversationId,
        };
      });
      setFilteredMessages(fiteredMessage);
      setTimeout(() => {
        const fiteredMessage = messages?.map((mess) => {
          return {
            ...mess,
            isDislike: false,
          };
        });
        setFilteredMessages(fiteredMessage);
      }, 1500);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <div className={`main ${expanded ? "mainwithoutSidebar " : ""}`}>
      <div className="navbar">
        <FaBars size={"2rem"} onClick={handleButtonClick} />
        <p>IBGPT</p>
        <FaRegEdit
          size={"2rem"}
          onClick={() =>
            dispatch(
              editMessagesByHistory([
                {
                  text: "How can i help you today?",
                  isBot: true,
                },
              ])
            )
          }
        />
      </div>
      <div className="dividerbar"></div>
      <div onClick={handleButtonClick} className="arrows">
        <span className="togglesidebar">
          {expanded ? (
            <MdOutlineArrowForwardIos />
          ) : (
            <MdOutlineArrowBackIosNew />
          )}
        </span>
      </div>
      <div className="chats">
        {filteredMessages?.map((message, index) => (
          <div key={index} className={"chat"}>
            <div className="chatnameandimage">
              {/* <div> */}
              <img
                src={
                  message.isBot
                    ? IBGPT
                    : // : googleLogin
                      userDetails?.picture || user
                  // : user
                }
                alt="img"
                className={
                  // "chatImg"
                  message.isBot ? "botImg" : "chatImg"
                }
              />
              {/* </div> */}
              {message?.isBot ? <h3>IBGPT</h3> : <h3>You</h3>}
            </div>
            <div className="chatContent">
              {editIndex === index ? (
                <div className="editText">
                  <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                  />
                  <div className="updateCancelButtons">
                    <button className="updateButton" onClick={handlesend}>
                      Save & Submit
                    </button>
                    <button className="cancelButton" onClick={cancelEditing}>
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <p>
                  <ReactMarkdown>
                    {message.text || "Failed to load Data"}
                  </ReactMarkdown>
                </p>
              )}

              {message.isBot ? (
                <div className="chatfootericons">
                  <div
                    onClick={() => copyText(message)}
                    className="copytexticon"
                  >
                    {message?.isCopy ? (
                      <IoCheckmark />
                    ) : (
                      <IconContext.Provider value={{ color: "white" }}>
                        <div>
                          <MdContentCopy />
                        </div>
                      </IconContext.Provider>
                    )}
                  </div>
                  <div
                    className="copytexticon"
                    onClick={() => changeLike(message)}
                  >
                    <IconContext.Provider
                      value={{
                        color: "white",
                        size: "15px",
                      }}
                    >
                      <div>
                        {message.isLike ? <AiFillLike /> : <AiOutlineLike />}
                      </div>
                    </IconContext.Provider>
                  </div>
                  <div
                    className="copytexticon"
                    onClick={() => handleDislikefeedback(message)}
                  >
                    <IconContext.Provider
                      value={{
                        color: "white",
                        size: "15px",
                      }}
                    >
                      <div>
                        {message.isDislike ? (
                          <AiFillDislike />
                        ) : (
                          <AiOutlineDislike />
                        )}
                      </div>
                    </IconContext.Provider>
                  </div>
                </div>
              ) : (
                <div className="editmessageicon">
                  {!editing && (
                    <CiEdit
                      size={"1.6rem"}
                      onClick={() => startEditing(index)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
        <div ref={msgEnd} />
      </div>
      <div className="chatFooter">
        <div className="inp">
          <label className="custom-file-upload">
            <input type="file" />
            <IoFolderOpen size={"2rem"} />
          </label>
          <input
            disabled={status ? true : false}
            type="text"
            placeholder="Message IBGPT... "
            value={editing ? "" : input}
            onKeyDown={handleEnter}
            onChange={(e) => setInput(e.target.value)}
          />
          <button className="send" onClick={handlesend}>
            {status ? (
              <IconContext.Provider
                value={{ color: "white", className: "global-class-name" }}
              >
                <div>
                  <ImSpinner3 className="spin" />
                </div>
              </IconContext.Provider>
            ) : (
              <img src={sendBtn} alt="" />
            )}
          </button>
        </div>
        <p>IBGPT can make mistakes. Consider checking important information.</p>
      </div>
    </div>
  );
};

export default Main;
