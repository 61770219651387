import React, { useEffect, useState } from "react";
import "./Login.css";
import IBGPT from "../ChatGPT_Clone_assets/assets/IBGPT Logo black without bg.svg";
import whiteIBGPT from "../ChatGPT_Clone_assets/assets/IBGPT Logo white with black bg.jpg";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
// import { useUserContext } from "../context/ContextApi";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  changeGoogleLoginConditon,
  getUserDetails,
} from "../Slices/UserConditonsSlice";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [renderHeading, setRenderHeading] = useState("");
  const [renderText, setRenderText] = useState("");
  const [displayWords, setDisplayedWords] = useState([]);

  useEffect(() => {
    const chat = [
      {
        h: "LEARN  ",
        text: "any metric |! any metric |! any industry",
      },
      {
        h: "ASK",
        text: "any metric |! any metric |! any industry |! Diligence lists",
      },
      {
        h: "BENCHMARK",
        text: "any metric |! any metric |! any industry |! any company",
      },
      {
        h: "COMPARABLE COMPANY ANALYSIS",
        text: "at scale |! at scale |! at your fingertips",
      },
      {
        h: "ANALYSIS",
        text: "any metric |! any metric |! any company |! since 2010",
      },
    ];
    const displayNextChat = (index) => {
      const nextIndex = (index + 1) % chat.length;
      setDisplayedWords([]);
      setRenderText(chat[nextIndex].text);
      setRenderHeading(chat[nextIndex].h);
      setTimeout(() => {
        displayNextChat(nextIndex);
      }, 5000);
    };
    displayNextChat(-1);
  }, []);
  useEffect(() => {
    // console.log("renderlength", renderText.length, "renderText", renderText);
    if (renderText.length > 0) {
      const words = renderText.split("!");
      // console.log("words", renderHeading, ">>", words, "length", words.length);
      let index = 0;
      const intervalId = setInterval(() => {
        if (index < words.length) {
          // console.log("index||", index);
          // console.log("wordsindex", words[index]);
          setDisplayedWords((prevWords) => [...prevWords, words[index]]);
          index += 1;
        } else {
          clearInterval(intervalId);
        }
      }, 500); // Adjust the delay between words

      return () => clearInterval(intervalId);
    }
  }, [renderHeading, renderText]);
  // const { setUserDetails, setGoogleLogin } = useUserContext();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...res.data,
          })
        );
        if (res.status === 200) {
          // console.log("res||", res);
          dispatch(getUserDetails(res.data));
          dispatch(changeGoogleLoginConditon(true));
          navigate("/ibgpt/home");
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
  });
  return (
    <div className="loginPage">
      <div className="leftSide">
        <div className="upperLeft">
          <div className="upperLeftchild">
            <p>IBGPT</p>
            {/* <div> */}
            <img src={IBGPT} alt="logo" className="iblogo" />
            {/* </div> */}
          </div>
        </div>
        <div className="middleleft">
          <div className="aitext">
            <h1>First AI For Investment Banking</h1>
          </div>
          <div className="randomText">
            <h3 className="fristtext">{renderHeading}</h3>
            <p>{displayWords}</p>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="gptname">
          <div className="gptnamedetails">
            <p>IBGPT</p>
            <img src={IBGPT} alt="logo" />
          </div>
        </div>
        <div className="logindetails">
          <div className="rightupperdetails">
            <div className="upperText">
              <h1>Get Started</h1>
            </div>
            <div>
              <button
                className="googlebutton"
                style={{
                  background: "white",
                  color: "black",
                  border: "1px solid  skyblue",
                }}
                onClick={() => login()}
              >
                <FcGoogle />
                Continue with Google
              </button>
              {/* <button>Sign up</button> */}
            </div>
          </div>
          <div className="aboutgpt">
            <div className="gpttext">
              <img src={whiteIBGPT} alt="" />
              IBGPT
            </div>
            <div className="termsandconditions">
              <p>Terms of use</p>
              <h4>|</h4>
              <p>Privacy policy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
